import _, { isArray } from 'underscore';
import moment from 'moment';
import Util from '@/util';

const defaultState = {
  mapMode: 'day',
  mapCenter: null,
  mapZoom: null,
  show_config: false,
  show_cameras: true,
  show_lprs: true,
  show_buses: true,
  show_devices: true,
  show_sensors: true,
  show_incidents_group: true,
  show_incidents: true,
  show_incidents_heat_map: true,
  show_geofences: true,
  show_live_route: false,
  show_tables: false,
  murphy_widget: false,
  indicadores_widget: false,
  sosActiveCall: null,
  config: {
    cameras: {
      status: ['up'],
    },
    buses: {
      status: ['up', 'alert', 'reported'],
    },
    lprs: {
      status: ['up'],
    },
    devices: {
      status: ['online'],
    },
    sensors: {
      status: ['alert', 'reported'],
    },
    incidents: {
      category: ['proteccion_civil', 'servicios_publicos', 'medico', 'seguridad'],
    },
  },
  selectedItem: undefined,
  camera_windows: {},
};

const resolveStatus = (item) => {
  return Util.statusMap[item.status];
};

const fieldsMap = {
  typeOfItems: {
    lprs: 'LPR',
    cameras: 'Camara',
    buses: 'Transporte',
    devices: 'Unidad',
    sensors: 'Botón de Pánico',
    incidents: 'Incidente',
    hitRoboVehiculo: 'Robo de Vehiculo',
    hitLpr: 'Deteccion por LPR',
    hitMandamiento: 'Mandamiento',
    sosIncident: 'Incidente SOS',
    sosCall: 'Llamada SOS',
    rapidsosIncident: 'Incidente Uber',
    onstarIncident: 'Incidente Onstar',
    repotant: 'Reportante',
    sismujeres: 'Sismujeres',
    geofence: 'Geocerca',
  },
  geofence: [
    {
      field: 'name',
      fieldName: 'NOMBRE',
      type: 'titleValue',
    },
  ],
  lprs: [
    {
      field: 'name',
      fieldName: 'NOMBRE',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
    {
      field: resolveStatus,
      fieldName: 'ESTATUS',
    },
    {
      field: (item) => moment(item.lastUpdate).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN Actualización',
    },
    {
      field: 'longitude',
      fieldName: 'LONGITUD',
    },
    {
      field: 'latitude',
      fieldName: 'LATITUD',
    },
    {
      field: 'address',
      fieldName: 'DIRECCIÓN',
    },
  ],
  cameras: [
    {
      field: 'name',
      fieldName: 'NOMBRE',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
    {
      field: resolveStatus,
      fieldName: 'ESTATUS',
    },
    {
      field: (item) => moment(item.lastUpdate).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: 'longitude',
      fieldName: 'LONGITUD',
    },
    {
      field: 'latitude',
      fieldName: 'LATITUD',
    },
    {
      field: 'address',
      fieldName: 'DIRECCIÓN',
    },
  ],
  buses: [
    {
      field: 'name',
      fieldName: 'NOMBRE',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
    {
      field: resolveStatus,
      fieldName: 'ESTATUS',
    },
    {
      field: (item) => moment(item.lastUpdate).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: 'longitude',
      fieldName: 'LONGITUD',
    },
    {
      field: 'latitude',
      fieldName: 'LATITUD',
    },
    {
      field: 'address',
      fieldName: 'DIRECCIÓN',
    },
    {
      field: 'numberOfCameras',
      fieldName: 'CAMARAS',
    },
    {
      field: 'phone',
      fieldName: 'TELÉFONO',
    },
    {
      field: 'contact',
      fieldName: 'CONTACTO',
    },
  ],
  devices: [
    {
      field: (item) => {
        let lte = '<img src="img/lte.png" width="32" height="32" style="image-orientation: none; background: black; border-radius:20%"/>'
        let type = '<img src="img/crp.png" width="32" height="32" style="image-orientation: none; border-radius:20%"/>'
        let image = '<img src="img/user2.png" width="120" height="150" style="image-orientation: none; border-color: azure; border-width: 1px; border-style: solid;"/>'
        if (item.user?.foto) {
          image = '<img src="data:image/png;base64,' + item.user?.foto + '" width="120" height="150" style="object-fit: cover; image-orientation: none; border-color: azure; border-width: 1px; border-style: solid;"/>'
        }
        const person = [
          'ayudantia', 'bombero', 'estatalp', 'ministerialp', 'militar', 'municipalp', 'elementoPcivil'
        ]

        const noLTE = [
          'arrow', 'dif', 'stj'
        ]
        if (item.uniqueId.includes('kenwood')) {
          lte = '<img src="img/kenwood.png" width="32" height="32" style="image-orientation: none; border-radius:20%"/>'
        }

        if (person.includes(item.category)) {
          type = '<img src="img/port.png" width="32" height="32" style="image-orientation: none; border-radius:20%"/>'
        }
        if (noLTE.includes(item.category)) {
          lte = '<img src="img/gps.png" width="32" height="32" style="image-orientation: none; background: white; border-radius:20%"/>'
          type = ''
        }
        return `
          <div style="position: absolute">
            <div>
              ${lte}
            </div>
            <div>
              ${type}
            </div>
          </div>
          <div>
            ${image}
          </div>
        `
      },
      fieldName: 'FOTO',
    },
    {
      field: 'name',
      fieldName: 'DISPOSITIVO',
    },
    {
      field: (item) => item.user?.nombre || 'Desconocido',
      fieldName: 'USUARIO',
    },
    {
      field: (item) => item.user?.dependencia || 'Desconocido',
      fieldName: 'DEPENDENCIA',
    },
    {
      field: (item) => item.user?.corporacion || 'Desconocido',
      fieldName: 'CORPORACION',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
    {
      field: 'phone',
      fieldName: 'TELEFONO',
    },
    {
      field: (item) => item.attributes?.conductor || 'Desconocido',
      fieldName: 'CONDUCTOR',
    },
    {
      field: (item) => moment(item.lastUpdate).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: resolveStatus,
      fieldName: 'ESTATUS',
    },
    {
      field: (item) => {
        // console.log(item)
        // return `<a href="https://maps.google.com/maps?q=${item.latitude}%2C${item.longitude}&z=17&hl=es" target="_blank"> ${(item.latitude + '0000000000').substring(0,10)}, ${(item.longitude + '0000000000').substring(0,10)} </a>`
        let str = '<a href="https://maps.google.com/maps?q=';
        str += item.position?.latitude;
        str += '%2C';
        str += item.position?.longitude;
        str += '&z=17&hl=es" target="_blank"> ';
        str += (item.position?.latitude + '0000000000').substring(0, 10);
        str += ', ';
        str += (item.position?.longitude + '0000000000').substring(0, 10);
        str += '</a>';
        return str;
      },
      fieldName: 'UBICACIÓN',
    },
    {
      field: (item) => item.position?.accuracy + ' KM',
      fieldName: 'PRECISIÓN',
    },
    {
      field: (item) => item.position?.speed?.toFixed(1) + ' km/h',
      fieldName: 'VELOCIDAD',
    },
    {
      field: (item) => item.position?.protocol,
      fieldName: 'PROTOCOLO',
    },
    {
      field: (item) => item.position?.attributes?.distance + ' KM',
      fieldName: 'DISTANCIA',
    },
    {
      field: (item) => (item.position?.attributes?.motion ? 'Si' : 'No'),
      fieldName: 'MOVIMIENTO',
    },
    {
      field: (item) => {
        const options = {
          None: 'Ninguna',
          Whatsapp: 'Whatsapp',
          Telegram: 'Telegram',
        }
        return options[item.position?.attributes?.instantMessenger] || 'Ninguna'
      },
      fieldName: 'TIPO DE ALERTA',
    },
    {
      field: (item) => {
        if (!item.position?.attributes?.phoneList) {
          return ''
        }
        return item.position?.attributes?.phoneList.joint(', ')
      },
      fieldName: 'DESTINATARIOS',
    },
    {
      field: 'uniqueId',
      fieldName: 'IDENTIFICADOR',
    },
  ],
  sensors: [
    {
      field: 'name',
      fieldName: 'IDENTIFICADOR',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
    {
      field: (item) => moment(item.lastUpdate).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: 'fiscalName',
      fieldName: 'NOMBRE FISCAL',
    },
    {
      field: (item) => {
        switch (item.status) {
          case 'up':
            return 'En Linea';
          case 'down':
            return 'Fuera de Linea';
          case 'alert':
            return 'Alertado';
          case 'reported':
            return 'Reportado';
          default:
            return 'Desconocido';
        }
      },
      fieldName: 'ESTATUS',
    },
    {
      field: 'address',
      fieldName: 'DIRECCIÓN',
    },
    {
      field: 'neighborhood',
      fieldName: 'COLONIA',
    },
    {
      field: 'city',
      fieldName: 'MUNICIPIO',
    },
    {
      field: 'phone',
      fieldName: 'TELÉFONO',
    },
    {
      field: 'contact',
      fieldName: 'NOMBRE DEL CONTACTO',
    },
    {
      field: 'contactJob',
      fieldName: 'PUESTO DEL CONTACTO',
    },
    {
      field: 'contactPhone',
      fieldName: 'TÉLEFONO DEL CONTACTO',
    },
    {
      field: 'contactEmail',
      fieldName: 'CORREO DEL CONTACTO',
    },
  ],
  incidents: [
    {
      field: 'uniqueId',
      fieldName: 'IDENTIFICADOR',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
    {
      field: (item) => item?.attributes?.fecha?.replaceAll('/', '-'),
      fieldName: 'FECHA',
    },
    {
      field: (item) => moment(item.lastUpdate || item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: (item) => item.attributes?.prioridad,
      fieldName: 'PRIORIDAD',
    },
    {
      field: (item) => item.tipoIncidente,
      fieldName: 'MOTIVO',
    },
    {
      field: (item) => {
        // console.log(item)
        // return `<a href="https://maps.google.com/maps?q=${item.latitude}%2C${item.longitude}&z=17&hl=es" target="_blank"> ${(item.latitude + '0000000000').substring(0,10)}, ${(item.longitude + '0000000000').substring(0,10)} </a>`
        let str = '<a href="https://maps.google.com/maps?q=';
        str += item.latitude;
        str += '%2C';
        str += item.longitude;
        str += '&z=17&hl=es" target="_blank"> ';
        str += (item.latitude + '0000000000').substring(0, 10);
        str += ', ';
        str += (item.longitude + '0000000000').substring(0, 10);
        str += '</a>';
        return str;
      },
      fieldName: 'UBICACIÓN',
    },
    {
      field: (item) => {
        return `${item.attributes?.lugar?.trim()}, ${item.attributes?.colonia?.trim()}, ${item.attributes?.localidad?.trim() ? item.attributes?.localidad?.trim() : 'Desconocido'}, ${item.attributes?.municipio?.trim()}`;
      },
      fieldName: 'DIRECCIÓN',
    },
    {
      field: (item) => item.attributes?.referencias,
      fieldName: 'REFERENCIAS',
    },
    {
      field: (item) => item.attributes?.descripcion,
      fieldName: 'DESCRIPCIÓN',
    },
    // {
    //   field: (item) => {
    //     let str = '';
    //     if (isArray(Object.keys(item?.attributes?.razonamientos))) {
    //       Object.keys(item.attributes.razonamientos).forEach((mp) => {
    //         str += '<b>' + mp + '</b> <br>';
    //         str += (item.attributes.razonamientos[mp] === 'None'
    //           ? 'Sin Razonamiento'
    //           : item.attributes?.razonamientos[mp]) + ' <br>';
    //       });
    //     }
    //     return str;
    //   },
    //   fieldName: 'RAZONAMIENTO',
    // },
  ],
  lprCaptureHit: [
    {
      field: 'name',
      fieldName: 'LPR',
    },
    {
      field: (item) => moment(item.attributes?.hit.fecha_hora_registro).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'FECHA DE HIT',
    },
    {
      field: (item) => item.attributes?.hit?.fecha_robo,
      fieldName: 'FECHA DE ROBO',
    },
    {
      field: (item) => (item.attributes?.placa || 'NULL') + ' / ' + (item.attributes?.hit?.vin || 'NULL'),
      fieldName: 'PLACA / SERIE',
    },
    {
      field: (item) => {
        return [item.attributes?.marca, item.attributes?.hit?.submarca, item.attributes?.hit?.modelo, item.attributes?.hit?.color].filter(a => a).join(' ')
      },
      fieldName: 'VEHICULO',
    },
    {
      field: (item) => item.attributes?.hit?.averiguacion,
      fieldName: 'AVERIGUACIÓN',
    },
    {
      field: (item) => {
        if (!item.attributes?.foto) {
          return ''
        }
        return `<a href="${item.attributes.foto}" target="_blank"><img style="width: 400px" src="${item.attributes.foto}" /></a>`
      },
      type: 'photo',
    }
  ],
  hitLpr: [
    {
      field: (item) => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: (item) => item.attributes?.placa,
      fieldName: 'PLACA',
    },
    {
      field: (item) => item.attributes?.serie,
      fieldName: 'SERIE',
    },
    {
      field: (item) => item.attributes?.marca,
      fieldName: 'MARCA',
    },
    {
      field: (item) => item.attributes?.linea,
      fieldName: 'LINEA',
    },
    {
      field: (item) => item.attributes?.modelo,
      fieldName: 'MODELO',
    },
    {
      field: 'name',
      fieldName: 'LPR',
    },
    {
      field: 'userId',
      fieldName: 'ID CONSULTÓ',
    },
  ],
  hitRoboVehiculo: [
    {
      field: 'name',
      fieldName: 'USUARIO CONSULTÓ',
    },
    {
      field: 'userId',
      fieldName: 'ID CONSULTÓ',
    },
    {
      field: (item) => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.PLACA,
      fieldName: 'PLACA',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.SERIE,
      fieldName: 'SERIE',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.TARJETA,
      fieldName: 'TARJETA',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.MARCA,
      fieldName: 'MARCA',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.LINEA,
      fieldName: 'LINEA',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.COLOR,
      fieldName: 'COLOR',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.MODELO,
      fieldName: 'MODELO',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.CLASE_VEHICULO,
      fieldName: 'CLASE',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.TIPO_VEHICULO,
      fieldName: 'TIPO',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.PLACA,
      fieldName: 'PLACA',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.NOMBRE,
      fieldName: 'PROPIETARIO',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.POBLACION,
      fieldName: 'POBLACIÓN',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.DOMICILIO,
      fieldName: 'DOMICILIO',
    },
    {
      field: (item) => item.attributes?.Vehiculo?.RFC,
      fieldName: 'RFC',
    },
  ],
  hitMandamiento: [
    {
      field: (item) => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: (item) => item.attributes?.Mandamientos?.expediente,
      fieldName: 'EXPEDIENTE',
    },
    {
      field: (item) => item.attributes?.Mandamientos?.dnombre,
      fieldName: 'NOMBRE',
    },
    {
      field: (item) => item.attributes?.Mandamientos?.dpaterno,
      fieldName: 'PRIMER APELLIDO',
    },
    {
      field: (item) => item.attributes?.Mandamientos?.dmaterno,
      fieldName: 'SEGUNDO APELLIDO',
    },
    {
      field: 'name',
      fieldName: 'USUARIO CONSULTÓ',
    },
    {
      field: 'userId',
      fieldName: 'ID CONSULTÓ',
    },
  ],
  sosIncident: [
    {
      field: 'uniqueId',
      fieldName: 'IDENTIFICADOR',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
    {
      field: (item) => item?.attributes?.fecha?.replaceAll('/', '-'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: (item) => moment(item.lastUpdate || item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: (item) => item.attributes?.prioridad,
      fieldName: 'PRIORIDAD',
    },
    {
      field: (item) => item.tipoIncidente,
      fieldName: 'MOTIVO',
    },
    {
      field: (item) => {
        // console.log(item)
        // return `<a href="https://maps.google.com/maps?q=${item.latitude}%2C${item.longitude}&z=17&hl=es" target="_blank"> ${(item.latitude + '0000000000').substring(0,10)}, ${(item.longitude + '0000000000').substring(0,10)} </a>`
        let str = '<a href="https://maps.google.com/maps?q=';
        str += item.latitude;
        str += '%2C';
        str += item.longitude;
        str += '&z=17&hl=es" target="_blank"> ';
        str += (item.latitude + '0000000000').substring(0, 10);
        str += ', ';
        str += (item.longitude + '0000000000').substring(0, 10);
        str += '</a>';
        return str;
      },
      fieldName: 'UBICACIÓN',
    },
    {
      field: (item) => {
        return `${item.attributes?.lugar?.trim()}, ${item.attributes?.colonia?.trim()}, ${item.attributes?.localidad?.trim() ? item.attributes?.localidad?.trim() : 'Desconocido'}, ${item.attributes?.municipio?.trim()}`;
      },
      fieldName: 'DIRECCIÓN',
    },
    {
      field: (item) => item.attributes?.referencias,
      fieldName: 'REFERENCIAS',
    },
    {
      field: (item) => item.attributes?.descripcion,
      fieldName: 'DESCRIPCIÓN',
    },
    {
      field: (item) => item.sosData.gender,
      fieldName: 'GÉNERO',
    },
    {
      field: (item) => item.sosData.bloodType,
      fieldName: 'Rh',
    },
    {
      field: (item) => item.sosData.emergencyContactName,
      fieldName: 'CONTACTO DE EMERGENCIA',
    },
    {
      field: (item) => item.sosData.emergencyContactPhone,
      fieldName: 'TEL. CONTACTO',
    },
    {
      field: (item) => (item.status === 'active' ? 'Activo' : 'Cerrado'),
      fieldName: 'ESTATUS SOS',
    },
  ],
  rapidsosIncident: [
    {
      field: (item) => {
        switch (item.status) {
          case 'up':
            return 'En Linea';
          case 'down':
            return 'Fuera de Linea';
          case 'alert':
            return 'Alertado';
          case 'reported':
            return 'Reportado';
          default:
            return 'Desconocido';
        }
      },
      fieldName: 'ESTATUS',
    },
    {
      field: 'riderName',
      fieldName: 'NOMBRE DEL CONDUCTOR',
    },
    {
      field: 'riderPhone',
      fieldName: 'TELÉFONO DEL CONDUCTOR',
    },
    {
      field: 'vehiclePlate',
      fieldName: 'PLACA',
    },
    {
      field: 'vehicleMake',
      fieldName: 'MARCA DEL VEHICULO',
    },
    {
      field: 'vehicleModel',
      fieldName: 'LINEA DEL VEHICULO',
    },
    {
      field: 'vehicleColor',
      fieldName: 'COLOR DEL VEHICULO',
    },
    {
      field: (item) => {
        // console.log(item)
        // return `<a href="https://maps.google.com/maps?q=${item.latitude}%2C${item.longitude}&z=17&hl=es" target="_blank"> ${(item.latitude + '0000000000').substring(0,10)}, ${(item.longitude + '0000000000').substring(0,10)} </a>`
        let str = '<a href="https://maps.google.com/maps?q=';
        str += item.latitude;
        str += '%2C';
        str += item.longitude;
        str += '&z=17&hl=es" target="_blank"> ';
        str += (item.latitude + '0000000000').substring(0, 10);
        str += ', ';
        str += (item.longitude + '0000000000').substring(0, 10);
        str += '</a>';
        return str;
      },
      fieldName: 'UBICACIÓN',
    },
    {
      field: 'uniqueId',
      fieldName: 'IDENTIFICADOR',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
  ],
  onstarIncident: [
    {
      field: 'uniqueId',
      fieldName: 'IDENTIFICADOR',
    },
    {
      field: 'groupName',
      fieldName: 'GRUPO',
    },
    {
      field: (item) => item?.attributes?.fecha?.replaceAll('/', '-'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: (item) => moment(item.lastUpdate || item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
      fieldName: 'ULTIMA ACTUALIZACIÓN',
    },
    {
      field: (item) => item.attributes?.prioridad,
      fieldName: 'PRIORIDAD',
    },
    {
      field: (item) => item.tipoIncidente,
      fieldName: 'MOTIVO',
    },
    {
      field: (item) => {
        // console.log(item)
        // return `<a href="https://maps.google.com/maps?q=${item.latitude}%2C${item.longitude}&z=17&hl=es" target="_blank"> ${(item.latitude + '0000000000').substring(0,10)}, ${(item.longitude + '0000000000').substring(0,10)} </a>`
        let str = '<a href="https://maps.google.com/maps?q=';
        str += item.latitude;
        str += '%2C';
        str += item.longitude;
        str += '&z=17&hl=es" target="_blank"> ';
        str += (item.latitude + '0000000000').substring(0, 10);
        str += ', ';
        str += (item.longitude + '0000000000').substring(0, 10);
        str += '</a>';
        return str;
      },
      fieldName: 'UBICACIÓN',
    },
    {
      field: (item) => {
        return `${item.attributes?.lugar?.trim()}, ${item.attributes?.colonia?.trim()}, ${item.attributes?.localidad?.trim() ? item.attributes?.localidad?.trim() : 'Desconocido'}, ${item.attributes?.municipio?.trim()}`;
      },
      fieldName: 'DIRECCIÓN',
    },
    {
      field: (item) => item.attributes?.referencias,
      fieldName: 'REFERENCIAS',
    },
    {
      field: (item) => item.attributes?.descripcion,
      fieldName: 'DESCRIPCIÓN',
    },
  ],
  sismujeres: [
    {
      field: (item) => item._movementData.additionalData.phone,
      fieldName: 'TELEFONO',
    },
    {
      field: (item) => item._movementData.additionalData.name,
      fieldName: 'NOMBRE',
    },
    {
      field: (item) => item._movementData.additionalData.car,
      fieldName: 'VEHICULO',
    },
    {
      field: (item) => {
        let str = '<a href="https://maps.google.com/maps?q=';
        str += item._movementData.latitude;
        str += '%2C';
        str += item._movementData.longitude;
        str += '&z=17&hl=es" target="_blank"> ';
        str += (item._movementData.latitude + '0000000000').substring(0, 10);
        str += ', ';
        str += (item._movementData.longitude + '0000000000').substring(0, 10);
        str += '</a>';
        return str;
      },
      fieldName: 'UBICACIÓN',
    },
  ],
  reportant: [
    {
      field: (item) => item.additionalData.phone,
      fieldName: 'TELEFONO',
    },
    {
      field: (item) => {
        const location = item.positions[0];
        return moment(parseInt(location.additionalData.location_time)).format('DD-MM-YYYY HH:mm:ss')
      },
      fieldName: 'FECHA DE LLAMADA',
    },
    {
      field: (item) => {
        const location = item.positions[item.positions.length - 1];
        return moment(parseInt(location.additionalData.location_time)).format('DD-MM-YYYY HH:mm:ss')
      },
      fieldName: 'FECHA DE ACTUALIZACIÓN',
    },
    {
      field: (item) => {
        const location = item.positions[item.positions.length - 1];
        return location.additionalData.positioning_method;
      },
      fieldName: 'ORIGEN',
    },
    {
      field: (item) => {
        const location = item.positions[item.positions.length - 1];
        let str = '<a href="https://maps.google.com/maps?q=';
        str += location.latitude;
        str += '%2C';
        str += location.longitude;
        str += '&z=17&hl=es" target="_blank"> ';
        str += (location.latitude + '0000000000').substring(0, 10);
        str += ', ';
        str += (location.longitude + '0000000000').substring(0, 10);
        str += '</a>';
        return str;
      },
      fieldName: 'UBICACIÓN',
    },
    {
      field: (item) => {
        const location = item.positions[item.positions.length - 1];
        return location?.additionalData?.address?.direccion || 'Desconocido';
      },
      fieldName: 'DIRECCIÓN',
    },
    {
      field: (item) => {
        const location = item.positions[item.positions.length - 1];
        return location?.additionalData?.address?.municipio || 'Desconocido';
      },
      fieldName: 'MUNICIPIO',
    },
    {
      field: (item) => {
        const location = item.positions[item.positions.length - 1];
        return location?.additionalData?.address?.poblacion || 'Desconocido';
      },
      fieldName: 'POBLACIÓN',
    },
    {
      field: (item) => {
        const location = item.positions[item.positions.length - 1];
        return location?.additionalData?.address?.colonia || 'Desconocido';
      },
      fieldName: 'COLONIA',
    },
    {
      field: (item) => {
        const location = item.positions[item.positions.length - 1];
        return location?.additionalData?.address?.cp || 'Desconocido';
      },
      fieldName: 'CP',
    },
    {
      field: (item) => `RapidSOS <img style="height: 25px;" src="rapidsos.png"></img>`,
      fieldName: 'FUENTE',
    },
  ],
  incidentMovement: [
    {
      field: 'uniqueId',
      fieldName: 'IDENTIFICADOR',
    },
    {
      field: (item) => item.attributes?.numeroReportante,
      fieldName: 'TELEFONO',
    },
    {
      field: (item) => {
        return moment(parseInt(item._movementData?.additionalData?.created_time || '0')).format('DD-MM-YYYY HH:mm:ss')
      },
      fieldName: 'FECHA',
    },
    {
      field: (item) => item._movementData?.additionalData?.positioning_method,
      fieldName: 'ORIGEN',
    },
    {
      field: (item) => {
        // console.log(item)
        // return `<a href="https://maps.google.com/maps?q=${item.latitude}%2C${item.longitude}&z=17&hl=es" target="_blank"> ${(item.latitude + '0000000000').substring(0,10)}, ${(item.longitude + '0000000000').substring(0,10)} </a>`
        let str = '<a href="https://maps.google.com/maps?q=';
        str += item._movementData?.additionalData?.latitude;
        str += '%2C';
        str += item._movementData?.additionalData?.longitude;
        str += '&z=17&hl=es" target="_blank"> ';
        str += (item._movementData?.additionalData?.latitude + '0000000000').substring(0, 10);
        str += ', ';
        str += (item._movementData?.additionalData?.longitude + '0000000000').substring(0, 10);
        str += '</a>';
        return str;
      },
      fieldName: 'UBICACIÓN',
    },
    {
      field: (item) => item?._movementData?.additionalData?.address?.direccion || 'Desconocido',
      fieldName: 'DIRECCIÓN',
    },
    {
      field: (item) => item?._movementData?.additionalData?.address?.municipio || 'Desconocido',
      fieldName: 'MUNICIPIO',
    },
    {
      field: (item) => item?._movementData?.additionalData?.address?.poblacion || 'Desconocido',
      fieldName: 'POBLACIÓN',
    },
    {
      field: (item) => item?._movementData?.additionalData?.address?.colonia || 'Desconocido',
      fieldName: 'COLONIA',
    },
    {
      field: (item) => item?._movementData?.additionalData?.address?.cp || 'Desconocido',
      fieldName: 'CP',
    },
    {
      field: (item) => `RapidSOS <img style="height: 25px;" src="rapidsos.png"></img>`,
      fieldName: 'FUENTE',
    },
  ],
  geofencePoint: [{
    field: 'folio',
    fieldName: 'FOLIO'
  }, {
    field: 'conductor',
    fieldName: 'CONDUCTOR'

  }],
  sosCall: [{
    field: (item) => moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    fieldName: 'FECHA'
  }, {
    field: (item) => {
      switch (item.type) {
        case 'VIDEO_CALL':
          return 'Video Llamada';
        case 'CALL':
          return 'Llamada de Voz';
        case 'CHAT':
          return 'Chat';
        default:
          return 'Desconocido';
      }
    },
    fieldName: 'TIPO'
  }, {
    field: (item) => `${item.userData.name} ${item.userData.lastName1} ${item.userData.lastName2}`,
    fieldName: 'NOMBRE'
  }, {
    field: (item) => `${item.userData.address}, ${item.userData.neighborhood}, ${item.userData.city}`,
    fieldName: 'DIRECCION'
  }, {
    field: (item) => item.userData.municipality,
    fieldName: 'MUNICIPIO'
  }, {
    field: (item) => item.userData.phoneNumber,
    fieldName: 'TELEFONO'
  }, {
    field: (item) => item.userData.genre,
    fieldName: 'GENERO'
  }, {
    field: (item) => item.userData.bloodType,
    fieldName: 'TIPO DE SANGRE'
  }, {
    field: (item) => item.userData.alergies,
    fieldName: 'ALERGIAS'
  }, {
    field: (item) => item.userData.diseases,
    fieldName: 'ENFERMEDADES'
  }, {
    field: (item) => item.userData.emergencyContactName,
    fieldName: 'CONTACTO DE EMERGENCIA'
  }, {
    field: (item) => item.userData.emergencyContactPhoneNumber,
    fieldName: 'TELEFONO DE EMERGENCIA'
  }, {
    field: (item) => item.userData.email,
    fieldName: 'CORREO'
  }, {
    field: (item) => item.status,
    fieldName: 'ESTATUS'
  }]
};

const getters = {
  getMapMode: (state) => state.mapMode,
  getShowingElements: (state) => state.showingElements,
  getShowCameras: (state) => state.show_cameras,
  getShowLprs: (state) => state.show_lprs,
  getShowBuses: (state) => state.show_buses,
  getShowDevices: (state) => state.show_devices,
  getShowSensors: (state) => state.show_sensors,
  getShowIncidentsGroup: (state) => state.show_incidents_group,
  getShowIncidents: (state) => state.show_incidents,
  getShowIncidentsHeatMap: (state) => state.show_incidents_heat_map,
  getShowGeofences: (state) => state.show_geofences,
  getSelectedItem: (state) => state.selectedItem,
  getMapCenter: (state) => state.mapCenter,
  getMapZoom: (state) => state.mapZoom,
  getCamerasFilters: (state) => ({
    ...state.config.cameras,
    status: state.show_cameras ? state.config.cameras.status : [],
  }),
  getBusesFilters: (state) => ({
    ...state.config.buses,
    status: state.show_buses ? state.config.buses.status : [],
  }),
  getLprsFilters: (state) => ({
    ...state.config.lprs,
    status: state.show_lprs ? state.config.lprs.status : [],
  }),
  getDevicesFilters: (state) => ({
    ...state.config.devices,
    status: state.show_devices ? state.config.devices.status : [],
  }),
  getSensorsFilters: (state) => ({
    ...state.config.sensors,
    status: state.show_sensors ? state.config.sensors.status : [],
  }),
  getIncidentsFilters: (state) => ({
    ...state.config.incidents,
    status: state.show_incidents ? state.config.incidents.category : [],
  }),
};

const actions = {
  setMapMode({ commit }, mode) {
    commit('SET_MAP_MODE', mode);
  },
  changeShowingElements({ commit }, elements) {
    commit('SET_SHOWING_ELEMENTS', elements);
  },
  setShowCameras({ commit }, flag) {
    commit('SET_SHOW_CAMERAS', flag);
  },
  setShowLprs({ commit }, flag) {
    commit('SET_SHOW_LPRS', flag);
  },
  setShowBuses({ commit }, flag) {
    commit('SET_SHOW_BUSES', flag);
  },
  setShowDevices({ commit }, flag) {
    commit('SET_SHOW_DEVICES', flag);
  },
  setShowSensors({ commit }, flag) {
    commit('SET_SHOW_SENSORS', flag);
  },
  setShowIncidentsGroup({ commit }, flag) {
    commit('SET_SHOW_INCIDENTS_GROUP', flag);
  },
  setShowIncidents({ commit }, flag) {
    commit('SET_SHOW_INCIDENTS', flag);
  },
  setShowIncidentsHeatMap({ commit }, flag) {
    commit('SET_SHOW_INCIDENTS_HEAT_MAP', flag);
  },
  setShowGeofences({ commit }, flag) {
    commit('SET_SHOW_GEOFENCES', flag);
  },
  toggleShowLiveRoute({ commit }) {
    commit('TOGGLE_SHOW_LIVE_ROUTE');
  },
  toggleShowTables({ commit }) {
    commit('TOGGLE_SHOW_TABLES');
  },
  setSelectedItem({ commit }, item) {
    commit('SET_SELECTED_ITEM', item);
  },
  toggleShowConfig({ commit }) {
    commit('TOGGLE_SHOW_CONFIG');
  },
  setFilter({ commit }, options) {
    commit('SET_FILTER', options);
  },
  toggleMurphyWidget({ commit }) {
    commit('TOGGLE_MURPHY_WIDGET');
  },
  toggleIndicadoresWidget({ commit }) {
    commit('TOGGLE_INDICADORES_WIDGET');
  },
  setActiveCall({ commit }, value) {
    commit('SET_ACTIVE_CALL', value);
  },
};

const mutations = {
  SET_MAP_MODE(state, mode) {
    state.mapMode = mode;
  },
  SET_SHOWING_ELEMENTS(state, elements) {
    state.showingElements = elements;
  },
  SET_SHOW_CAMERAS(state, flag) {
    state.show_cameras = flag;
  },
  SET_SHOW_LPRS(state, flag) {
    state.show_lprs = flag;
  },
  SET_SHOW_BUSES(state, flag) {
    state.show_buses = flag;
  },
  SET_SHOW_DEVICES(state, flag) {
    state.show_devices = flag;
  },
  SET_SHOW_SENSORS(state, flag) {
    state.show_sensors = flag;
  },
  SET_SHOW_INCIDENTS_GROUP(state, flag) {
    state.show_incidents_group = flag;
  },
  SET_SHOW_INCIDENTS(state, flag) {
    state.show_incidents = flag;
  },
  SET_SHOW_INCIDENTS_HEAT_MAP(state, flag) {
    state.show_incidents_heat_map = flag;
  },
  SET_SHOW_GEOFENCES(state, flag) {
    state.show_geofences = flag;
  },
  TOGGLE_SHOW_LIVE_ROUTE(state) {
    state.show_live_route = !state.show_live_route;
  },
  TOGGLE_SHOW_TABLES(state) {
    state.show_tables = !state.show_tables;
  },
  SET_SELECTED_ITEM(state, item) {
    if (!item) {
      state.selectedItem = undefined;
      return;
    }
    const fields = fieldsMap[item.typeOfItem];
    const fieldsData = fields.map((i) => ({
      label: i.fieldName,
      value: _.isFunction(i.field) ? i.field(item) : item[i.field],
      type: i.type,
    }));
    state.selectedItem = {
      type: fieldsMap.typeOfItems[item.typeOfItem],
      actions: item.actions,
      data: item._infoFields ? [...fieldsData, ...item._infoFields] : fieldsData,
    };
  },
  TOGGLE_SHOW_CONFIG(state) {
    state.show_config = !state.show_config;
  },
  SET_FILTER(state, { key, filters }) {
    state.config[key] = filters;
  },
  TOGGLE_MURPHY_WIDGET(state) {
    state.murphy_widget = !state.murphy_widget;
  },
  TOGGLE_INDICADORES_WIDGET(state) {
    state.indicadores_widget = !state.indicadores_widget;
  },
  SET_ACTIVE_CALL(state, value) {
    state.sosActiveCall = value;
  }
};

export default {
  namespaced: true,
  state() {
    return defaultState;
  },
  getters,
  actions,
  mutations,
};
